import { lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfigProvider } from "@2jprocess/carton-ui";
import { useCurrentLocale } from "@eprev/i18n";

import { NotFound } from "./404";
import { BrowserAnalytics } from "./browser-analytics";
import { GlobalActivityLoader } from "./components";
import { AuthProvider } from "./auth";
import { queryClient } from "./lib/query-client";
import { RoleBalancer } from "./acl/components/role-balancer";
import { ProtectedRoute } from "./acl/components/protected-route";
import { CanBeLearner } from "./acl/components/can-be-learner";
import { AlertBox } from "./components/alert-box";

const LearnerRoutes = lazy(() => import("./learner/routes"));
const OrgRoutes = lazy(() => import("./organization/routes"));
const AccountPage = lazy(() => import("./profile"));
const NewPasswordPage = lazy(() => import("./new-password"));
const CompleteExtrafieldsPage = lazy(() => import("./complete-extrafields"));
const LoginPage = lazy(() => import("./login"));
const LoginLinkPage = lazy(() => import("./login/login-link"));
const LegalPage = lazy(() => import("./legal"));
const PlayerPage = lazy(() => import("./player"));
const RegisterPage = lazy(() => import("./register"));
const ResetPassword = lazy(() => import("./login/reset-password"));
const AdminRoutes = lazy(() => import("./admin/routes"));
const DocsPage = lazy(() => import("./docs"));
const ETLoginPage = lazy(() => import("./et/auth/login"));
const ETLoginLinkPage = lazy(() => import("./et/auth/login/login-link"));

export function App() {
  const locale = useCurrentLocale();

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <BrowserAnalytics />
        <GlobalActivityLoader />
        <AlertBox />
        <ConfigProvider value={{ locale }}>
          <Router>
            <Routes>
              <ProtectedRoute path="/new-password" element={<NewPasswordPage />} />
              <ProtectedRoute path="/complete-extrafields" element={<CompleteExtrafieldsPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/et/login" element={<ETLoginPage />} />
              <Route path="/et/login-link/:token" element={<ETLoginLinkPage />} />
              <Route path="/login-link/:token" element={<LoginLinkPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/reset-password/:token" element={<ResetPassword />} />
              <Route path="/legal" element={<LegalPage />} />
              <ProtectedRoute path="/" element={<RoleBalancer />} />
              <ProtectedRoute redirectTo="/et/login" path="/et" element={<RoleBalancer />} />
              <ProtectedRoute
                path="learner/*"
                element={
                  <CanBeLearner>
                    <LearnerRoutes />
                  </CanBeLearner>
                }
              />
              <ProtectedRoute path="org/*" element={<OrgRoutes />} />
              <Route path="admin/*" element={<AdminRoutes />} />
              <ProtectedRoute path="/profile" element={<AccountPage />} />
              <ProtectedRoute path="/docs/*" element={<DocsPage />} />
              <ProtectedRoute path="/player/*" element={<PlayerPage />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
        </ConfigProvider>
      </AuthProvider>

      <ReactQueryDevtools position="bottom-left" />
    </QueryClientProvider>
  );
}
