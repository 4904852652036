import React from "react";
import { UAParser } from "ua-parser-js";

import { http } from "~/lib/http";
import { useAuth } from "~/auth";

export function BrowserAnalytics() {
  const { user } = useAuth();
  React.useEffect(() => {
    if (!user?.id) return;
    try {
      const key = `bowser_${user.id}`;
      const inStorage = window.sessionStorage.getItem(key);
      if (inStorage) return;

      const parser = new UAParser();
      const ua = parser.getResult();

      http
        .post("me/bowser", {
          json: ua
        })
        .then(() => {
          window.sessionStorage.setItem(key, "1");
        });
    } catch (error) {}
  }, [user?.id]);

  return null;
}
